<template>
  <div class="wrap">
    <div class="banner">
      <img v-if="clientWidth > 750" src="@/assets/PC/activity/banner_wx.png" alt="">
      <img v-else src="@/assets/PC/activity/banner_wx_m.png" alt="">
    </div>
    <div class="register">
      <div class="register-body">
        <div class="register-content" :class="clientWidth > 750 ? 'flex0' : ''">
          <div class="register-detail">
            <!-- <h1>感谢您对本次活动的关注和支持，为更好地为您提供服务，调查问卷。</h1> -->
            <el-form :model="registerForm" :rules="rules" ref="registerForm" class="registerForm">
              <el-form-item prop="lastName" label="姓">
                <el-input v-model.trim="registerForm.lastName" placeholder="请输入姓氏"></el-input>
              </el-form-item>
              <el-form-item prop="firstName" label="名">
                <el-input v-model.trim="registerForm.firstName" placeholder="请输入名字"></el-input>
              </el-form-item>
              <el-form-item prop="email" class="form-item email_input" label="邮箱">
                <el-input v-model.trim="registerForm.email" placeholder="请输入您的邮箱地址"></el-input>
              </el-form-item>
              <el-form-item prop="phone" label="手机号">
                <el-input v-model.trim="registerForm.phone" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <!-- 输入搜索公司 -->
              <el-form-item prop="company" label="公司">
                <el-select v-model="registerForm.company" placeholder="请输入公司" ref="select" filterable remote reserve-keyword :remote-method="remoteMethod" :loading="loading" @focus="clear" @hook:mounted="clear" @visible-change="clear">
                  <el-option v-for="(item, index) in searchOptions" :key="index" :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="industrySecond" multiple label="行业">
                <el-cascader
                    v-model="IndustryItems"
                    :options="Module.industrys"
                    placeholder="请选择行业(可多选)"
                    :props="props"
                    @change="IndustryItem1"></el-cascader>
              </el-form-item>
              <el-form-item prop="industryOther" v-if="IsIndustryOther" label="其他">
                <el-input v-model.trim="registerForm.industryOther" placeholder="请输入行业"></el-input>
              </el-form-item>
              <el-form-item prop="scope" label="职位">
                <el-cascader
                    v-model="ScodeItems"
                    :options="Module.position"
                    placeholder="请选择职位"
                    :props="props1"
                    @change="ScodeItem1"></el-cascader>
              </el-form-item>
              <el-form-item prop="scopeOther" v-if="IsOther" label="其他">
                <el-input v-model.trim="registerForm.scopeOther" placeholder="请输入职位"></el-input>
              </el-form-item>
              <el-form-item prop="province" label="所在省份">
                <el-select v-model="registerForm.province" placeholder="请选择所在省份">
                    <el-option v-for="item in cityList" :key="item.province" :label="item.province" :value="item.province"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item prop="session" label="参加的场次" v-if="false">
                <el-select v-model="registerForm.session" multiple placeholder="请选择参加的场次，可多选">
                    <el-option v-for="item in sessionList" :key="item.id" :label="item.treeName" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <div class="privacy-text">
                <el-checkbox v-model="privacy" class="flex">
                    我确认已阅读
                    <a href="https://www.aveva.com/en/legal/privacy-policy/" target="_blank" >AVEVA 隐私政策</a>
                    并确认我的个人数据的处理详情。
                </el-checkbox>
              </div>
              <el-form-item class="flex0">
                <el-button type="info" size="medium" :loading="loading" @click="submitForm('registerForm')" class="pc-register loginBtn" >提 交</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>

    <div class="LoginAlert">
      <div v-if="showDialog" class="shade flex0">
        <div class="shade-section tc">
          <div class="shade-section-title" v-if="emailShow">您已经报名，如需重新报名，请联系<br>剑盟社区工作人员<br><a href="mailto:Roadshow@avevacommunity.com">Roadshow@avevacommunity.com</a></div>
          <div class="shade-section-title" v-else v-html="dialogContent"></div>
          <div class="shade-section-btn">
            <button type="button" class="xy-btn" v-if="btnShow" @click="goNext">立即前往</button>
          </div>
          <i class="el-icon-circle-close close-alert co-fff cur" v-if="closeShow" @click="closeDialog"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getCompany, GetUserRecordActivitySelect, AddUserRecordAct, CheckActStatus} from "@/api/register.js";
import { GetCompanyList } from "@/api/Home.js";
import { ApiToken } from '@/utils/ApiToken'
let cityList = [
      {province:'北京市'},
      {province:'天津市'},
      {province:'河北省'},
      {province:'山西省'},
      {province:'内蒙古自治区'},
      {province:'辽宁省'},
      {province:'吉林省'},
      {province:'黑龙江省'},
      {province:'上海市'},
      {province:'江苏省'},
      {province:'浙江省'},
      {province:'安徽省'},
      {province:'福建省'},
      {province:'江西省'},
      {province:'山东省'},
      {province:'河南省'},
      {province:'湖北省'},
      {province:'湖南省'},
      {province:'广东省'},
      {province:'广西壮族自治区'},
      {province:'海南省'},
      {province:'重庆市'},
      {province:'四川省'},
      {province:'贵州省'},
      {province:'云南省'},
      {province:'西藏自治区'},
      {province:'陕西省'},
      {province:'甘肃省'},
      {province:'青海省'},
      {province:'宁夏回族自治区'},
      {province:'新疆维吾尔自治区'},
      {province:'台湾省'},
      {province:'香港特别行政区'},
      {province:'澳门特别行政区'},
    ]

export default {
  data(){
    return{
      props: { multiple: true, value: 'value', label: 'text', children: 'list' },
      props1: { multiple: false, value: 'value', label: 'text', children: 'list' },
      loading: false,
      cityList: cityList,
      sessionList: [],

      showDialog: false,
      dialogContent: '您已成功报名活动<br>前往剑盟社区，了解更多<br>剑维软件信息',
      emailShow: false,
      closeShow: false,
      btnShow: false,
      
      IndustryItems:'',                    // !行业多选
      ScodeItems: '',                      // !职位单选
      loading: false,                      // !登录loading
      options: [],                         // !公司数据
      searchOptions: [],                   // !搜索公司数据
      privacy: false,                      // !隐私链接是否选中

      track: this.$route.query.track ? this.$route.query.track : '',
      registerForm: {
        lastName: "",
        firstName: "",
        // name: "",                          // !昵称
        phone: "",                         // !手机号
        email: "",                         // !邮箱
        company: "",                     // !公司Id
        province: "",
        industry: "",
        industrySecond: "",
        industryOther: "",                   // !其他行业
        positionId: '',                         // !职位
        scope: '',                         // !职位
        scopeOther: '',                    // !其他职位
        session: ''
      },
      IsIndustryOther: false,              // !行业选择其他
      IsOther: false,                      // !职位选择其他
      IsSecondOther: false,

      rules: {
        lastName: [
          { required: true, message: "请输入姓氏", trigger: "blur" },
          { min: 1, max: 30, message: "长度在1个字符至20个字符之间", trigger: "blur"},
        ],
        firstName: [
          { required: true, message: "请输入名字", trigger: "blur" },
          { min: 1, max: 30, message: "长度在1个字符至20个字符之间", trigger: "blur"},
        ],
        // name: [
        //   { required: true, message: "请输入姓名", trigger: "blur" },
        //   { min: 2, max: 30, message: "长度在2个字符至30个字符之间", trigger: "blur"},
        // ],
        phone: [
          { required: true, message: "请输入您的手机号", trigger: "blur" },
          { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,message: "请输入正确的手机号",trigger: "blur"},
        ],
        email: [
          { required: true, message: "请输入您的邮箱地址", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        company: [{ required: true, message: "请输入公司名称", trigger: "change" }],
        province: [{ required: true, message: "请选择所在省份", trigger: "change" }],
        industrySecond: [{ required: true, message: "请选择行业", trigger: "change" }],
        industryOther: [{ required: true, message: "请输入行业", trigger: "blur" }],
        // positionId: [{ required: true, message: "请选择工作职能", trigger: "change", }],
        scope: [{ required: true, message: "请选择职位", trigger: "change" }],
        scopeOther: [{ required: true, message: "请输入职位", trigger: "blur" }],
        session: [{ required: true, message: "请选择参加的场次", trigger: "change" }],
      },
    }
  },
  mounted() {
    this.showDialog = true
    this.dialogContent = '活动报名已结束,<br>感谢您的关注与支持。'
    this.closeShow = false

    // !获取公司名称
    getCompany().then((res) => {
      this.options = res.data;
    });
    GetUserRecordActivitySelect().then(res => {
      this.sessionList = res.data
    })
  },
  methods: {
    goNext(){
      this.showDialog = false
      this.$router.push('/Home/Index')
    },
    closeDialog() {
      this.showDialog = false
      this.$refs.registerForm.resetFields(); 
      this.privacy = false;
      this.loading = false;
      this.IndustryItems = '';
      this.ScodeItems = '';
      this.registerForm.industry = ""
      this.registerForm.positionId = ""
    },
    handleChange(val){
      if(val.includes('其他')){
        this.IsSecondOther = true
      }else{
        this.IsSecondOther = false
      }
    },
    clear(async) {
      this.$nextTick(() => {
        if (!async) {
          // ios 手机有延迟问题
          setTimeout(() => {
            const { select } = this.$refs
            const input = select.$el.querySelector('.el-input__inner')
            input.removeAttribute('readonly')
          }, 200)
        }
      })
    },
    // 搜索公司
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.GetCompanyList(query);
        }, 200);
      }
    },
    // 获取公司列表
    GetCompanyList(key) {
      GetCompanyList({keyword: key}).then(res => {
        this.searchOptions = res.data
      })
    },
    // 行业选择
    IndustryItem1(){
      var arr = []
      var arr_children = []
      this.IndustryItems.forEach(item => {
        arr.push(item[0])
        arr_children.push(item[1])
      })

      // 判断选择其他时
      let textArr = []
      this.$store.state.Module.industrys.forEach(item => {
        if(arr.includes(item.value)){
          textArr.push(item.text)
        }
      })
      if(textArr.includes('其他')){
        this.IsIndustryOther = true
      }else{
        this.IsIndustryOther = false
        this.registerForm.industryOther = ""
      }
      
      this.registerForm.industry = [...new Set(arr)].join(',')
      this.registerForm.industrySecond = arr_children.join(',')
    },
    // 职位选择
    ScodeItem1() {
      this.registerForm.positionId = parseInt(this.ScodeItems[0])
      this.registerForm.scope = parseInt(this.ScodeItems[1])
      var str
      this.$store.state.Module.position.filter(item => {
        item.list.filter(val => {
          if(val.value == this.ScodeItems[1]){
            str = val.text
          }
        })
      })
      if(str == '其他') {
        this.IsOther = true
      } else {
        this.IsOther = false
        this.registerForm.scopeOther = ""
      }
    },
    // !报名
    submitForm(formName) {
      
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.privacy) {
            this.loading = true;
            const registerForm = JSON.parse(JSON.stringify(this.registerForm))  
            registerForm.track = this.$route.query.track ? this.$route.query.track : ''
            registerForm.activitySession = '2023 AVEVA 合作伙伴“超燃精英”工程师训练营-无锡站'
            registerForm.session = ApiToken.trainingWx
            registerForm.activityType = ApiToken.trainingForm

            this.showDialog = true
            this.dialogContent = '活动报名已结束,<br>感谢您的关注与支持。'
            this.closeShow = false
            // AddUserRecordAct(registerForm).then((res) => {
            //   if (res.status == 1) {
            //     // this.$toast.success({ message: "已完成！", duration: 1000 });
            //     this.showDialog = true
            //     this.dialogContent = '您已成功报名活动<br>前往剑盟社区，了解更多<br>剑维软件信息'
            //     this.emailShow = false
            //     this.closeShow = true
            //     this.btnShow = true
            //   }else if(res.status == -101 || res.status == -102) {
            //     this.showDialog = true
            //     this.emailShow = true
            //     this.btnShow = false
            //     this.closeShow = true
            //   } else{
            //     this.loading = false;
            //     this.$toast(res.message);
            //   }
            // });
          } else {
            this.$alert("请先阅读并勾选论坛协议！", "提示", {
              confirmButtonClass: "el-button--mini",
              callback: (action) => {},
            });
            return false;
          }
        } else {
          this.loading = false;
          return false;
        }
      });
    },
  },
  computed: {
    ...mapState(['clientWidth','Module'])
  },
};
</script>
<style lang="scss">
.el-cascader-menu:nth-child(even) {
  .el-checkbox {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
  }
  .el-cascader-node__label{
    margin-left: 10px;
  }
  .el-scrollbar__bar {
    opacity: 0 !important;
  }
}
.el-scrollbar {
  .el-scrollbar__bar {
    opacity: 1 !important;
  }
}
</style>
<style lang="scss" scoped>
$mobil_base: 750;
@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}
.wrap{
    width: 100%;
    overflow: hidden;
    font-size: 30px;
}
.banner{
    width: 100%;
    height: auto;
    display: block;
    img{
      width: 100%;
      height: auto;
      display: block;
    }
}
.module{
    width: 1200px;
    margin: 0 auto;
}
.register{
  .register-body { padding: 15px 0; min-height: calc(100vh - 83px) }
  .register-content { width: 100%; max-width: 1200px; margin: 0 auto; overflow: hidden; }
  .register-logo img { width: 589px }
  .register-detail { box-shadow: 0 0 10px #ccc }
  .privacy-text,
  .subscription,
  .privacy-text label {font-size: 12px;margin-bottom: 10px;}
  .el-checkbox__label {white-space: normal!important;}
  /deep/ .el-checkbox__input.is-checked+.el-checkbox__label{color: #606266;}
  // /deep/ .el-radio-group{ min-width: 300px; }
  .privacy-text /deep/ .el-checkbox__inner,.subscription .el-checkbox__inner{margin-top: 3px;}
  .agrees {color: #606266; }
  .el-cascader{ width: 100%; }
}

@media (min-width: 750px) {
  .el-form-item{
    // margin-bottom: 14px;
    margin-bottom: 20px;
    display: flex;
    /deep/ .el-form-item__label{
      width: 200px !important;
    }
    /deep/ .el-input__inner{
      width: 500px;
    }
  }
  .privacy-text {margin-left: 200px;}
  .loginBtn{width: 200px; margin: 10px auto;}
  .register {
    background: #f7f7f7;
      .register-detail {
        padding: 30px 0;
        width: 850px;
        background: #fff;
        h1 {
          font-size: 20px;
          font-weight: bold;
          color: #363d42;
          line-height: 46px;
          text-align: center;
          margin-bottom: 15px;
        }
        .registerForm {
          width: 775px;
          margin: 0 auto;
          .other_input{
            margin-bottom: 10px;
            /deep/ .el-form-item__label{line-height: 20px; margin-top: 10px;}
            /deep/ .el-form-item__error{margin-top: -10px;}
          }
          .way_input{
            /deep/ .el-form-item__error{margin-top: -10px;}
            .notice{ font-size: 13px; color: red; line-height: 20px; }
          }
          .form-item {
            &.code_input {
              /deep/ .el-form-item__content {
                display: flex;
                justify-content: space-between;
                .el-input {
                  width: 150px;
                }
                .el-button {
                  width: 120px;
                  &:hover {
                    background: #fff;
                    color: #5f1593;
                  }
                }
              }
            }
          }
        }
      }
  }
}
@media (max-width: 750px) {
    .banner{
        img{
            width: vw(750);
        }
    }
  .el-form-item{
    margin-bottom: 14px;
  }
  .loginBtn{width: vw(300); margin: 10px auto;}
  .register /deep/ .el-checkbox__label {
    white-space: normal!important;
  }
  .register {
    min-height: 100vh; background: #f7f7f7;
    .register-body { padding: 0; }
    .register-logo { margin: vw(60) 0; text-align: center }
    .register-logo img { width: vw(321) }
    .register-detail { padding: vw(40); background: #fff; margin: vw(40); 
      .registerForm{
        .other_input{
          margin-bottom: vw(0);
            /deep/ .el-form-item__error{line-height: vw(15);}
        }
        .way_input{ margin: 0 0 8px; display: flex;flex-direction: column; align-items: flex-start;
          /deep/ .el-form-item__content{min-height: vw(30); line-height: vw(30);}
          /deep/ .el-form-item__error{line-height: vw(35);}
          .notice{ font-size: 12px; color: red; line-height: 20px; }
        }
      }
    }
    .privacy-text{margin-top: vw(40);}
    .privacy-text label a{font-size: 14px;}
    .register-detail h1 { font-size: vw(30); font-weight: bold; color: #363d42; line-height: vw(46); text-align: center; margin-bottom: vw(15);}
  }
}

// 弹窗样式
@media (min-width: 751px) {
  .LoginAlert {
    .shade-section { width: 450px; background: url('~@/assets/PC/InnovationContest/invite-friends.png') no-repeat; background-size: 100% 100%; padding: 110px 20px 40px 20px; position: relative; min-height: 300px }
    .shade-section-title {
      font-size: 24px;
      line-height: 1.5;
      margin-bottom: 15px;
      font-family: '黑粗';
      font-weight: bold;
      a{
        font-size: 22px;
        line-height: 1.5;
        margin-bottom: 15px;
        font-family: '黑粗';
        font-weight: bold;
        text-decoration: underline;
      }
    }
    
    .shade-section-btn {
      margin-top: 50px
    }

    .xy-btn { padding: 12px 20px; font-size: 14px; background: #6b18a8; color: #fff; display: inline-block; border: 0; border-radius: 5px; cursor: pointer; transition: all 0.3s }
    .xy-btn+.xy-btn { margin-left: 10px }
    .xy-btn:hover { opacity: 0.8 } 
  }
}

@media (max-width: 750px) {
  .LoginAlert {
    .shade-section { width: calc(100% - 20px); background: url('~@/assets/PC/InnovationContest/invite-friends.png') no-repeat; background-size: 100% 100%; padding: 90px 10px 40px 10px; position: relative; min-height: vw(300) }
    .shade-section-title {
      font-size: vw(40);
      line-height: 1.5;
      margin-bottom: 15px;
      font-family: '黑粗';
      font-weight: bold;
      a{
        font-size: vw(36);
        line-height: 1.5;
        margin-bottom: 15px;
        font-family: '黑粗';
        font-weight: bold;
        text-decoration: underline;
      }
    }
    
    .shade-section-btn {
      margin-top: 20px
    }
    .xy-btn { padding: 9px 15px; font-size: 12px; background: #6b18a8; color: #fff; display: inline-block; border: 0; border-radius: 3px }
    .xy-btn+.xy-btn { margin-left: 5px }
  }
}
</style>
